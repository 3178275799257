@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;500;600;700');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

:root {
  --primary-bg: #17111d;
  --primary-bg-soft: #1e1826;
  --primary-bg-light: #261f30;
  --primary-bg-dark: #1a1422;
  --primary-border: #433c5060;
  --primary-main: #bd314b;
  --primary-light: #d13653;
  --primary-dark: #a82c43;
  --primary-bg-highlight: #2e2635;
  --secondary-light: #606bbf;
  --secondary-main: #534d99;
  --secondary-dark: #3f397f;
  --text-primary: rgb(225, 220, 233);
  --text-secondary: rgba(185, 175, 202, 1);
  --text-disabled: rgba(145, 133, 155, 1);
  --success: #4caf50;
  --error: #a52525;
  --warning: #f2a00a;
  --secondary-light-rgb: 96, 107, 191;
  --secondary-main-rgb: 83, 77, 153;
  --secondary-dark-rgb: 24, 18, 43;
  --clash: 242, 160, 10;
  --gamdom: 0, 255, 134;
  --rgb-success: 76, 175, 80;
  --rgb-error: 165, 37, 37;
}

.loading {
  min-height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans', 'Segoe UI', 'Montserrat', 'Lato', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--primary-bg);
}


.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: var(--text-secondary);
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: inherit;
}

a:active {
  color: inherit;
}

.link-hover:hover {
  color: var(--secondary-light) !important;
}

.link {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  color: var(--primary-dark) !important;
}

.link:hover {
  color: var(--primary-light) !important;
}

.link:active {
  color: var(--primary-main) !important;
}

.link-secondary {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  color: var(--secondary-light) !important;
}

.link-secondary:hover {
  color: var(--secondary-main) !important;
}

.link-secondary:active {
  color: var(--secondary-dark) !important;
}

.page {
  width: 90%;
  max-width: 1700px;
  min-width: 300px;
  margin: 2rem auto 0 auto;
}

img {
  object-fit: contain !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

svg {
  font-size: 16;
  width: .8em;
  height: .8em;
  fill: white;

}

.styled-scroll {
  scrollbar-width: thin;
  scrollbar-color: rgb(100, 100, 140) rgb(42, 42, 64);
  
}

.styled-scroll::-webkit-scrollbar-track {
  background: var(42, 42, 64);
}

.styled-scroll::-webkit-scrollbar-thumb {
  background: rgb(100, 100, 140);
  border-radius: 6px;
  border: 3px solid rgb(42, 42, 64);
}

.styled-scroll::-webkit-scrollbar {
  height: 12px;
  background: rgb(42, 42, 64);
  border-radius: 6px;
}

.gold {
  color: rgb(202,171,5);
}

.covert {
  color: rgb(235,75,75);
}

.classified {
  color: rgb(211,44,230);
}

.restricted {
  color: rgb(136,71,255);
}

.milspec {
  color: rgb(17,85,221);
}

.Toastify__toast-theme--dark {
  background-color: rgb(20, 16, 24) !important;
  border-radius: 8px !important;
  border: 2px solid transparent;
  border-color: rgba(72, 64, 80);
  color: rgba(210, 210, 210, .9) !important;
}

.Toastify__toast-icon svg {
  height: 20px;
  width: 20px;
}


.Toastify__toast-container {
  width: auto !important;
}


.success-toast {
  background-color: #4caf50 !important;
  color: #fff !important;
  border-radius: 8px !important;
}

.error-toast {
  background-color: #c8354f !important;
  color: #fff !important;
  border-radius: 8px !important;
}

.Toastify__close-button svg {
  fill: #fff !important;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.inner {
  display: flex;
  column-gap: 10px;
  transition: transform 6s ease-in-out;
  transform: translateX(0px);
}

.inner .case-item {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .1;
  transition: opacity 125ms ease-in-out;
}

.case-item .case-item-image {
  height: 140px;
  width: 140px;
}

.item-won {
  animation: winAnimation 6s infinite;
}

.player-win {
  animation: winAnimation 6s infinite;
}

.item-shadow-common {
  filter: drop-shadow(0 0 1.5rem rgba(240, 240, 240, .4));
}

.item-shadow-uncommon {
  filter: drop-shadow(0 0 1.5rem rgba(33, 104, 156, .4));
}

.item-shadow-rare {
  filter: drop-shadow(0 0 1.5rem rgba(131, 64, 179, .6));
}

.item-shadow-epic {
  filter: drop-shadow(0 0 1.5rem rgba(131, 64, 179, .6));
}

.item-shadow-legendary {
  filter: drop-shadow(0 0 1.5rem rgba(212, 175, 55, .5));
}

@keyframes winAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px) scale(0.96);
  }
  50% {
    opacity: .2;
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes fadeInSmall {
  0% {
    opacity: 0;
    transform: translateY(25px) scale(0.97);
  }
  50% {
    opacity: .2;
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(25px) scale(0.98);
  }
  50% {
    opacity: .2;
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}


@keyframes opacityFadeIn {
  0% {
    display: flex;
    opacity: 0,
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1,
  }
}

.leaderboard-table {
  width: 100%;
  border-spacing: 0 8px; 
  overflow: auto;
}

.leaderboard-table tbody tr {
  border-radius: 8px;
  color: var(--text-secondary);
  cursor: pointer;
  position: relative;
}

.leaderboard-table thead tr {
  background-color: transparent;
}

.leaderboard-table thead tr th {
  padding: 1rem;
  font-weight: 500;
  color: var(--text-secondary);
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
}

.leaderboard-table tr td {
  padding: .75rem 1rem;
  background-color: var(--primary-bg-soft);
}

.leaderboard-table tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.leaderboard-table tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.leaderboard-table tbody tr::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: transparent;
  pointer-events: none;

}

.leaderboard-table tbody tr.first::after {
  background: linear-gradient(135deg, rgba(218, 165, 32, .08) 0%, rgba(0,0,0,0) 100%);
}

.leaderboard-table tbody tr.second::after {
  background: linear-gradient(135deg, rgba(192, 192, 192, .08) 0%, rgba(0,0,0,0) 100%);
}

.leaderboard-table tbody tr.third::after {
  background: linear-gradient(135deg, rgba(205, 127, 50, .08) 0%, rgba(0,0,0,0) 100%);
}

.leaderboard-table .placement {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block;
  line-height: 32px;
  text-align: center;
  width: 32px;
  border: 1px solid var(--text-disabled);
}

.leaderboard-table .first {
  color: #daa520;
  text-shadow: 0 0 2px #daa520;
  .placement {
    border-color: #daa520;
  }
  
}

.leaderboard-table .second {
  color: #c0c0c0;
  text-shadow: 0 0 2px #c0c0c0;
  .placement {
    border-color: #c0c0c0;
  }
}

.leaderboard-table .third {
  color: #cd7f32;
  text-shadow: 0 0 2px #cd7f32;
  .placement {
    border-color: #cd7f32;
  }
}


.leaderboard svg {
  width: 16px;
  height: 16px;
  margin-right: .25rem;
}

.avatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: .5rem;
  border: 2px solid transparent var(--primary-border);
  box-shadow: 0 0 .5rem rgba(24, 16, 32, .5);
}

.status-blob {
  background-color: var(--primary-bg-soft);
  border-radius: 50%;
  height: 12px;
  width: 12px;
  transform: scale(1);
  animation: pulse 3s infinite;
  
}

@keyframes pulse {
  0% {
    transform: scale(0.97);
    background-color: rgba(200, 32, 32, .3);
    box-shadow: 0 0 .5rem rgba(255, 0, 0, .3);

  }
  50% {
    transform: scale(1);
    background-color: rgba(200, 32, 32, .9);
    box-shadow: 0 0 .75rem rgba(255, 0, 0, .9);

  }

  100% {
    transform: scale(0.97);
    background-color: rgba(200, 32, 32, .3);
    box-shadow: 0 0 .5rem rgba(255, 0, 0, .3);

  }
}

.MuiLoadingButton-loadingIndicator {
  font-size: 14px;
  text-transform: capitalize;
}

.makeStyles-root-1 {
  align-items: center;
}

.makeStyles-root-1 button {
  margin-right: .5rem;
  
}

.makeStyles-root-1 button div {
  border: 2px solid rgba(255, 255, 255, .8);
  transition: background-color .2s ease-in-out;
}

.MuiClock-root button span {
  color: var(--text-primary) !important;
}

.embla {
  overflow: hidden;
  --slide-spacing: 8px;
  --slide-size: 100%;
  --slide-spacing-sm: 8px;
  --slide-size-sm: 50%;
  --slide-spacing-lg: 8px;
  --slide-size-lg: calc(100% / 3);
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
@media (min-width: 750px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-sm) * -1);
  }
}
@media (min-width: 1200px) {
  .embla__container {
    margin-left: calc(var(--slide-spacing-lg) * -1);
  }
}
.embla__slide {
  min-width: 0;
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
}
@media (min-width: 750px) {
  .embla__slide {
    flex: 0 0 var(--slide-size-sm);
    padding-left: var(--slide-spacing-sm);
  }
}
@media (min-width: 1200px) {
  .embla__slide {
    flex: 0 0 var(--slide-size-lg);
    padding-left: var(--slide-spacing-lg);
  }
}